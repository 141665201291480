import { createRouter, createWebHistory } from 'vue-router'

import Content from '../views/Content'
import NotFound from '../components/NotFound'
import Login from '../views/Login'

const routes = [
  { path: "/", name: "Обзор", component: Login },
  { path: "/login", name: "Login", component: Login },
  { path: "/terminals", name: "Terminals", component: Content, query: { filters: null }},
  { path: "/distributives", name: "Distributives", component: Content},
  { path: "/images", name: "Images", component: Content},
  { path: "/templates", name: "Templates", component: Content},
  { path: "/transport", name: "Transport", component: Content},
  { path: "/integration", name: "Integration", component: Content},
  { path: "/settings", name: "Settings", component: Content},
  { path: '/not_found', component: NotFound },  
  // { path: '*', redirect: '/404' },  
  { path: '/:pathMatch(.*)', redirect: '/not_found' }
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

export default router;
