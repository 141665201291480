import { createApp } from "vue";
import { panelBackUrl } from './config.js'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

const app = createApp(App);

app.config.globalProperties.$backEndUrl = panelBackUrl

app.config.globalProperties.$http = axios.create();

app.config.globalProperties.$http_task = axios.create();

app.config.globalProperties.$http.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `Bearer ${await store.getters
      .AUTH_TOKEN}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

app.config.globalProperties.$http_task.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `Bearer ${await store.getters
      .AUTH_TOKEN}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

app.config.globalProperties.$http_task.interceptors.response.use(
  async (response) => {
    let result = response;

    if (
      response &&
      response.status === 200 &&
      ["WAITING", "INPROCESS"].includes(response.data.payload.taskState)
    ) {
      let tries = 20;

      while (
        ["WAITING", "INPROCESS"].includes(response.data.payload.taskState) &&
        tries > 0
      ) {
        result = await app.config.globalProperties.$http
          .post(`${app.config.globalProperties.$backEndUrl}/v1/proxy/api`, {
            url: `/v2/task?taskId=${response.data.payload.taskId}`,
            method: "GET",
            payload: {},
            headers: {},
          })
          .then((res) => {
            if (["OK", "ERROR"].includes(res.data.payload.taskState)) {
              tries = 0;
              return res;
            }
          })
          .finally(() => {
            tries -= 1;
          });

        await new Promise((resolve) => {
          setTimeout(() => {
            console.log(
              "Retry to get result from task id:",
              response.data.payload.taskId
            );
            resolve();
          }, 1000);
        });
      }
    }
    return result;
  },
  async (error) => {
    if (error.response && error.response.status == 401) {
      console.log("Auth token expired try to refresh");
      const isUpdated = await store.dispatch("UPDATE_AUTH_TOKEN");
      if (isUpdated) {
        error.config.headers["Authorization"] = `Bearer ${await store.getters
          .AUTH_TOKEN}`;
        return app.config.globalProperties.$http.request(error.config);
      } else {
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

app.config.globalProperties.$http.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status == 401) {
      console.log("Auth token expired try to refresh");
      const isUpdated = await store.dispatch("UPDATE_AUTH_TOKEN");
      if (isUpdated) {
        error.config.headers["Authorization"] = `Bearer ${await store.getters
          .AUTH_TOKEN}`;
        return app.config.globalProperties.$http.request(error.config);
      } else {
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

app.use(store).use(router).mount("#app");
